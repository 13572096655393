import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ITSO from "../types/inventoryOrder";
import ClientAPI, { DEFAULT_CLIENT_SUCCESS_HANDLER } from "./ClientAPI";
import { ADD_ERROR_NOTIFICATION } from "@/types/notification";

export default class InventoryOrderService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async processInventoryOrder(rep_id: string, cust_id: string, parts: any[]) {
    const payload = {
      rep_id,
      cust_id,
      parts,
    };

    return ClientAPI.Post(
      `processInventoryOrder`,
      payload,
      null,
      DEFAULT_CLIENT_SUCCESS_HANDLER,
      (error) => {
        store.dispatch(ADD_ERROR_NOTIFICATION, error);
      },
    );
  }
}
