
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import useVuelidate, { ErrorObject } from "@vuelidate/core";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";
import { AxiosError } from "axios";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";

import Utils from "@/utility/utils";

// Types
import ITSO from "@/types/inventoryOrder";

// Services
import InventoryOrderService from "@/services/InventoryOrder";
import PartService from "@/services/PartsService";
const inventoryService = new InventoryOrderService(
  process.env.VUE_APP_ABSTRACTION_API,
);

const partService = new PartService();

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    InputNumber,
    Button,
    Dialog,
    Divider,
    MessageBox,
  },
  data() {
    return {
      displayConfirmation: false,
      displayQuantityConfirmation: false,
      displayViewParts: false,
      confirmedQuantity: false,
      loading: false,
      partLoading: false,
      loadCreateOrder: false,
      isPartSubmitted: false,
      rep: "",
      lastRep: "",
      cardHeight: "h-20rem",
      notes: "",
      part: "",
      orders: [] as string[],
      loadAddPart: false,
      quantity: "1",
      customer: "",
      desc: "",
      am_price: 0.0,
      showCustomer: false,
      errorMessage: "",
      showErrorDialog: false,
      focusRefName: "",
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
      processingOrderCreation: false,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    ...mapGetters({
      getStoreParts: "partsCheckout/getParts",
      getClient: "session/getClient",
    }),
    totalPrice() {
      let total = 0;
      this.getStoreParts.forEach((part: ITSO) => {
        total += part.am_price * part.qty;
      });
      return total;
    },
    partCount() {
      let count = 0;
      this.getStoreParts.forEach((part: ITSO) => {
        count += part.qty;
      });
      return count;
    },
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      addSalesOrders: "salesOrder/addSalesOrders",
      removeSalesOrders: "salesOrder/removeSalesOrders",
      clearSalesOrders: "salesOrder/clearSalesOrders",
      storePart: "partsCheckout/addPart",
      removePart: "partsCheckout/removePart",
      clearParts: "partsCheckout/clearParts",
      setParts: "partsCheckout/setParts",
    }),
    async addPart() {
      if (!this.rep) {
        this.handleErrorAndFocus("Rep field required.", "rep");
        return;
      }

      if (!this.notes) {
        this.handleErrorAndFocus("Notes field required.", "notes");
        return;
      }

      if (this.showCustomer) {
        if (!this.customer) {
          this.handleErrorAndFocus("Customer field required.", "customer");
          return;
        }
      } else if (!this.customer) {
        this.createCustomerFromRep();
      }

      if (!this.part) {
        this.handleErrorAndFocus("Part field required.", "part");
        return;
      }

      // Check if quantity is valid
      if (!this.confirmedQuantity) {
        this.handleQuantity();

        if (!this.confirmedQuantity) {
          return;
        }
      }

      if (!this.quantity) {
        this.handleErrorAndFocus("Quantity field required.", "quantity");
        return;
      }

      this.loadAddPart = true;

      const inventoryOrder: ITSO = {
        part_id: this.part.toUpperCase(),
        rep_id: this.rep,
        qty: parseInt(this.quantity, 10),
        note: this.notes,
        cust_id: this.customer,
        desc: this.desc,
        am_price: this.am_price,
      };

      this.storePart(inventoryOrder);

      this.clear();
      this.focusInput("part");
      this.loadAddPart = false;
    },
    async createOrder(parts: ITSO[]) {
      try {
        if (!this.rep) {
          this.handleErrorAndFocus("Rep field required.", "rep");
          return;
        }

        if (this.showCustomer) {
          if (!this.customer) {
            this.handleErrorAndFocus("Error adding customer.", "customer");
            return;
          }
        } else {
          this.createCustomerFromRep();
        }

        this.loadCreateOrder = true;
        type ProcessOrderOutput = {
          status?: string;
          error?: string;
          message?: string;
          so_ids?: string[];
        };
        const data = (await inventoryService.processInventoryOrder(
          this.rep,
          this.customer,
          parts.map((part) => ({
            ...part,
            qty: "" + part.qty,
            am_price: "" + part.am_price,
          })),
        )) as ProcessOrderOutput;

        // Determine if there's more than one SO ID to modify the success message
        const isMultipleOrders = (data?.so_ids?.length ?? 0) > 1;
        const successMessage = `Created Order${isMultipleOrders ? "s" : ""}`;

        this.clearAll();
        this.clearParts();
        this.focusInput("rep");

        const notification = {
          dialog: true,
          type: "success",
          message: `${successMessage} - ${data?.so_ids?.join(",") || ""}`,
        };
        this.addNotification(notification, { root: true });
      } catch (error: any) {
        this.handleErrorAndFocus(
          error.response?.data?.message || "Failed to submit",
          "part",
        );
      } finally {
        this.loadCreateOrder = false;
      }
    },
    handleNotes() {
      this.notes = Utils.validateScanInput(this.notes, false);

      if (!this.notes) {
        this.handleErrorAndFocus("Notes field required.", "notes");
      } else {
        this.focusInput("part");
      }
    },
    clear() {
      this.part = "";
      this.quantity = "1";
      this.am_price = 0.0;
      this.desc = "";
      this.confirmedQuantity = false;
      this.isPartSubmitted = false;
    },
    clearAll() {
      this.rep = "";
      this.customer = "";
      this.part = "";
      this.notes = "";
      this.quantity = "1";
      this.am_price = 0.0;
      this.desc = "";
      this.confirmedQuantity = false;
      this.isPartSubmitted = false;
    },
    openDialog() {
      this.displayConfirmation = true;
    },
    async addConfirmation() {
      if (!this.processingOrderCreation) {
        await this.submitParts();
      }
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    confirmQuantity() {
      this.displayQuantityConfirmation = false;
      this.confirmedQuantity = true;
    },
    cancelQuantity() {
      this.quantity = "1";
      this.displayQuantityConfirmation = false;
      this.focusInput("quantity");
    },
    async handlePart() {
      this.part = Utils.validateScanInput(this.part, true);

      if (!this.part) {
        this.handleErrorAndFocus("Part field required.", "part");
      } else {
        const client = this.getClient;
        this.partLoading = true;
        partService
          .getPartByID({
            client,
            id: this.part,
            fieldnames: "part_no wrap_desc",
            correls: "am_price",
          })
          .then((resp: any) => {
            this.part = resp?.part_no || "";
            this.desc = resp?.wrap_desc || "";
            this.am_price = parseFloat(resp?.am_price) || 0.0;
            this.focusInput("quantity");
          })
          .catch((err) => {
            this.handleErrorAndFocus("Error looking for part.", "part");
          })
          .finally(() => {
            this.partLoading = false;
          });
      }
    },

    isValidRep(rep: string): boolean {
      const parsedRep = parseInt(rep, 10);
      return (
        rep.length === 2 &&
        !isNaN(parsedRep) &&
        parsedRep >= 1 &&
        parsedRep <= 99
      );
    },
    handleRepBlur(event: any) {
      if (!this.rep) {
        return;
      }
      // Check if user tabbed away
      if (event.relatedTarget) {
        this.handleRep();
      }
    },
    handleRep() {
      if (!this.isValidRep(this.rep)) {
        this.handleErrorAndFocus("Rep must be a 2 digit number.", "rep");
        return; // If the rep is not valid, we don't want to continue further.
      }

      if (this.showCustomer) {
        this.focusInput("customer");
      } else {
        this.focusInput("notes");
      }
    },
    handleCustomer() {
      if (!this.customer) {
        this.handleErrorAndFocus("No customer assigned", "customer");
      } else {
        this.focusInput("part");
      }
    },
    handleQuantity() {
      this.quantity = Utils.validateScanInput(this.quantity, false);
      if (this.quantity) {
        const parsedQuantity = parseInt(this.quantity, 10);
        if (parsedQuantity >= 50 || !Number.isInteger(parsedQuantity)) {
          this.displayQuantityConfirmation = true;
        } else {
          this.confirmedQuantity = true;
        }
      } else {
        this.handleErrorAndFocus("Quantity field required", "quantity");
      }
    },
    createCustomerFromRep() {
      if (this.rep) {
        this.customer = "2" + this.rep.padStart(2, "0") + "H00";
      } else {
        this.customer = "";
      }
    },
    focusInput(refName: string) {
      if (refName) {
        (this.$refs[refName] as any).$el.focus();
        (this.$refs[refName] as any).$el.select();
      }
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      this.focusInput(this.focusRefName);
    },
    viewParts() {
      this.displayViewParts = true;
    },
    hidePartsDisplay() {
      this.displayViewParts = false;
    },
    async submitParts() {
      this.displayConfirmation = false;
      this.processingOrderCreation = true;

      try {
        await this.createOrder(this.getStoreParts);
      } catch (err: any) {
        this.handleErrorAndFocus(
          err.toString() || "Failed to add parts. Please try again.",
          "part",
        );
      } finally {
        this.displayViewParts = false;
        this.displayConfirmation = false;
        this.isPartSubmitted = true;
        this.focusInput("rep");
        this.processingOrderCreation = false; // Hide the processing dialog
      }
    },
    saveChanges() {
      this.setParts(this.getStoreParts);
      this.displayViewParts = false;
    },
  },
  mounted() {
    this.clear();
    this.focusInput("rep");
  },
});
